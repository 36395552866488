.visible {
    border: 2px solid black;
    padding: 10px;
    border-radius: 7px;
    margin: 14px 0;
}
.invisible {
    overflow: hidden;
    margin: 14px 0;
}
.inner_grid {
    box-sizing: border-box;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.inner_block {
    box-sizing: border-box;
    display: block;
}
.h3 {
    font-size: 1.3rem;
    margin: 5px 0;
    color: black;
}
