.imagesWrapper {
    display: flex;
    flex-direction: row;
    margin: 0 -5px;
    overflow: scroll;
    padding-top: 5px;
}
.imgContainerWrapper {
    position: relative;
    box-sizing: border-box;
}
.imgContainer {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 40px;
    max-height: 40px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
    overflow: hidden;
    margin: 5px;
    border-radius: 5px;
}
.img {
    max-height: 40px;
    margin: 0 auto;
}
.closeBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    background: white;
    border-radius: 8px;
    width: 15px;
    height: 15px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
    top: -5px;
    right: -5px;
    cursor: pointer;
}
