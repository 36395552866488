.wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    background: rgb(285, 285, 84, 1);
    height: 100%;
    width: 2px;
    transform: translateX(-50%);
    pointer-events: none;
}
