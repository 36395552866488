.input {
    box-sizing: border-box;
    border-radius: 5px;
    border: 2px solid black;
    box-shadow: none;
    width: 100%;
    height: 40px;
    font-size: 1rem;
    padding: 0 7px;
}
.label {
    font-size: 0.8rem;
    color: black;
    margin-top: 5px;
}
