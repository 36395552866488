.img {
    max-width: 40px;
}
.text {
    color: black;
    margin: 0 10px;
}
.wrapper {
    display: flex;
    align-items: center;
    margin: 5px 0;
}
