.wrapper {
    position: absolute;
    bottom: 0;
    z-index: 10;
    width: 200px;
    margin: 15px 0;
}
.inner {
    margin: 5px 15px 0 15px;
    padding: 5px 7px;
    background: white;
    border-radius: 5px;
}