.text {
    pointer-events: none;
    color: black;
    margin: 0 10px;
}
.flag {
    background: black;
    font-size: 4rem;
    width: 30px;
    height: 15px;
    position: absolute;
    top: 0;
    right: 0;
    transform-origin: bottom right;
    transform: rotate(45deg);
}
.wrapper_inactive {
    opacity: .3;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    background-size: cover;
    margin: 5px 5px 5px 0;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 2px solid black;
    transition: 0.2s;
}
.wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    background-size: cover;
    margin: 5px 5px 5px 0;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid black;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    transition: 0.2s;
}
.wrapper:hover {
    transform: translateY(-3px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.23);
}
