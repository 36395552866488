.white_backgdrop {
    position: relative;
    background: white;
    margin: 0 auto;
    height: fit-content;
    width: fit-content;
}
.inner {
    position: relative;
    height: 600px;
    width: 600px;
    background-image: linear-gradient(
            45deg,
            rgb(220, 220, 220) 25%,
            transparent 25%
        ),
        linear-gradient(-45deg, rgb(220, 220, 220) 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, rgb(220, 220, 220) 75%),
        linear-gradient(-45deg, transparent 75%, rgb(220, 220, 220) 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
.placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
