.wrapper {
    position: relative;
    display: flex;
    margin: 0 auto;
    width: fit-content;
    box-sizing: border-box;
    top: 0;
    padding: 10px 25px 25px 25px;
    max-width: 1300px;
}
.main {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    height: fit-content;
}
.footer {
    margin-top: 50px;
    width: 100%;
    height: 100px;
    border-top: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8c8c8c;
    font-size: 0.78em;
}

.footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.footer > img {
    width: 25px;
    height: 25px;
}
