.container {
    position: absolute;
    bottom: -18px;
    left: -21px;
    border-radius: 5px;
    background: black;
    padding: 5px 10px;
    margin: 5px;
    transform-origin: left center;
    transform: rotate(-90deg);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.349);
    cursor: pointer;
}
.inner {
    color: white;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-family: Arial, Helvetica, sans-serif;
}
.inner > p {
    margin: 0 0 0 5px;
    text-decoration: none;
    color: inherit;
    
}
