.container {
    height: 40px;
    width: 100%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 2px solid black;
    background: black;
    color: white;
    cursor: pointer;
    font-size: 1.1rem;
}
.container:hover {
    background: transparent;
    color: black;
}
.container > p {
    margin: 0;
}
.container_filled {
    background: black;
}
.container_inactive {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
}
.container_ghost {
    background: transparent;
    color: black;
    cursor: pointer;
}
.container_ghost:hover {
    background: black;
    color: white;
}
.spacer {
    width: 15px;
}
.container > svg {
    font-size: 1.5rem;
}
