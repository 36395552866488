.wrapper {
    display: flex;
    align-items: center;
    margin: 5px 0;
}
.text {
    color: black;
    margin: 0 10px;
}
.color {
    width: 40px;
    height: 40px;
    border: 2px solid black;
    border-radius: 5px;
    background: color;
    box-sizing: border-box;
    cursor: pointer;
}
.input {
    display: none;
}
